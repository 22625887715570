import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Section from "../components/Section";

interface Props {
  data: {
    allMarkdownRemark: {
      nodes: {
        frontmatter: {
          slug: string;
          title: string;
          date: string;
        };
        excerpt: string;
      }[];
    };
  };
}

const IndexPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout>
      <Section>
        {data.allMarkdownRemark.nodes.map((node) => (
          <ArticleBlock to={`/articles/${node.frontmatter.slug}`}>
            <ArticleTitle>{node.frontmatter.title}</ArticleTitle>
            <ArticleDate>{node.frontmatter.date}</ArticleDate>
            <ArticleExcerpt>{node.excerpt}</ArticleExcerpt>
          </ArticleBlock>
        ))}
      </Section>
    </Layout>
  );
};

const ArticleBlock = styled(Link)`
  margin-bottom: 50px;
  text-decoration: none;
  color: initial;
  display: block;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  & > * {
    margin: 7px 0px;
  }
`;

const ArticleTitle = styled.h2`
  font-size: 36px;
`;

const ArticleDate = styled.p`
  font-weight: bold;
  color: grey;
`;

const ArticleExcerpt = styled.p`
  font-size: 18px;
  font-family: "Merriweather", serif;
`;

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      nodes {
        frontmatter {
          slug
          title
          date(formatString: "M/D/YY")
        }
        excerpt
      }
    }
  }
`;
