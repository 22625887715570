import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Section from "./Section";

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Section>
        <NavBar>
          <NavTitle>One Big Blue</NavTitle>
          <NavLinks>
            <NavLink to="/" activeClassName="active">
              Home
            </NavLink>
            {/* <NavLink to="/today" activeClassName="active">
              Today
            </NavLink> */}
            <NavLink to="/about" activeClassName="active">
              About
            </NavLink>
          </NavLinks>
        </NavBar>
      </Section>
      {children}
    </>
  );
};

const NavBar = styled.nav`
  background-color: #446df6;
  height: 120px;
  padding: 0px 20px;
  display: flex;
`;

const NavTitle = styled.h1`
  display: inline-block;
  color: white;
  text-transform: lowercase;
  font-weight: 700;
  font-size: 72px;
  margin: 0;
  line-height: 120px;
`;

const NavLinks = styled.div`
  margin-left: auto;
`;

const NavLink = styled(Link)`
  display: inline-block;
  color: white;
  font-weight: 700;
  font-size: 36px;
  text-transform: lowercase;
  line-height: 120px;
  margin-left: 20px;
  text-decoration: none;

  &.active {
    font-style: italic;
  }
`;

export default Layout;
